
        import * as module0 from '$components/animations.css';import * as module1 from '$components/button.css';import * as module2 from '$components/contact.css';import * as module3 from '$components/contact.js';import * as module4 from '$components/hero.css';import * as module5 from '$components/logo.css';import * as module6 from '$components/modal.css';import * as module7 from '$components/modal.js';import * as module8 from '$components/review.css';import * as module9 from '$components/text.css'
        const modules = {
            "animations.css": module0,
            "button.css": module1,
            "contact.css": module2,
            "contact.js": module3,
            "hero.css": module4,
            "logo.css": module5,
            "modal.css": module6,
            "modal.js": module7,
            "review.css": module8,
            "text.css": module9,
        };
        export default modules;
      