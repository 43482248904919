document.addEventListener("DOMContentLoaded" , () => {
  [...document.querySelectorAll("[data-behavior=\"smooth-scroll\"]")].forEach(smoothScrollEl => {
    smoothScrollEl.addEventListener("click", event => {
      event.preventDefault();

      const target = document.getElementById(event.target.dataset.smoothScrollValue);
      target.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    });
  });
});
