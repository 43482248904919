document.addEventListener("DOMContentLoaded", () => {
  [...document.querySelectorAll("[data-behavior=\"modal\"]")].forEach(modelBtn => {
    const { dataset: { modalId: id, modalVisibilityClass: visibilityClass, modalPreventScrollClass: preventScrollClass } } = modelBtn;
    const modal = document.getElementById(id);
    const closeButton = modal.querySelector("[data-behavior=\"modal-close\"]");

    modelBtn.addEventListener("click", event => {
      event.preventDefault();
      modal.classList.add(visibilityClass);
      document.body.classList.add(preventScrollClass);
    });

    closeButton.addEventListener("click", event => {
      event.preventDefault();
      modal.classList.remove(visibilityClass);
      document.body.classList.remove(preventScrollClass);
    })
  });
});
